import { gsap } from 'gsap'
export default class Text {
    constructor(_title, args = {}) {
        this.container = _title
        this.bordered = this.container.classList.contains('bordered')
        this.text = this.container.innerHTML.toUpperCase()
        this.str = ''
        this.lineWidth = args.lineWidth || 1
        this.stroked = false
        this.border_width = this.width
        this.border_scale_x = 0
        //
        this.fontSize = 28
        this.createTimeline()
    }

    createTimeline() {
        const chars = this.text.split('');
        this.timeline = gsap.timeline({
            paused: true, onComplete: () => {
                this.str = this.text
                this.stroked = false
                this.updateHTML()
            }
        })
        this.timeline.to(this.container, {
            duration: 1,
            color: '#FFF',
            // ease: "steps(6)"
            ease: `steps(${chars.length})`
        }, 0)
        chars.forEach((char, index) => this.animateChar(index))
    }

    animateChar(charIdx) {
        const duration = 1 / this.text.length; // Duration for each random change
        const randomChars = '-/·=+*^%$#@!¡?¿';
        this.timeline.to({}, {
            duration: duration,
            onComplete: () => {
                let extraChar = randomChars.charAt(Math.floor(Math.random() * randomChars.length));
                this.str = this.text.slice(0, charIdx) + extraChar
                this.stroked = Math.random() > .5
                this.updateHTML()
            }
        }, charIdx)
    }

    updateHTML() {
        this.container.innerHTML = this.str
    }

    show(_duration = 1) {
        let duration = Math.max(.5, this.text.length * .1)
        duration = Math.min(3, duration)
        this.timeline.duration(_duration).play()
    }
    
    hide(_duration = 1) {
        let duration = Math.max(.5, this.text.length * .1)
        duration = Math.min(.5, _duration)
        this.str = ""
        // this.updateHTML()
        this.timeline.duration(duration).reverse()
    }

    getFontSize() {
        const style = window.getComputedStyle(this.container, null)
        return parseFloat(style.getPropertyValue('font-size'))
    }
    // getters
    get x() {
        return this.rect.left
    }

    get y() {
        /* El 10% es equivalente al line-height, que equivale a .8em
        *  este correspondería al alto de la fuente, por lo que el .2em restante es el espacio
        * que debe existir entre el aire superior del texto y el inferior. Resultando en [.2 / 2 = .1]
        */
        return this.rect.top + (this.height * .6)
    }

    get rect() {
        return this.container.getBoundingClientRect()
    }

    get width() {
        return this.rect.width
    }

    get height() {
        return this.rect.height
    }
}